html {
	overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*--------------------------------------------------------------
# Global
--------------------------------------------------------------*/
.page-title {
	margin: 0;
    font-size: 35px;
    font-weight: 400;
    font-family: '"Amatic SC", sans-serif';
	margin: 70px 0;
}

.page-title span {
	 color: #ce1212;
}
.img-shadow-left {
	filter: drop-shadow(-6px 6px 6px #ced4da);
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.app-header a.navbar-brand {
	font-size: 28px;
    font-weight: 700;
    color: #000;
    margin: 0;
}
.app-header a.navbar-brand span {
    color: #ce1212;
}
.app-header .navbar {
	border-bottom: 2px solid #dc3545;
}
.app-header .navbar .nav-item {
    white-space: nowrap;
    padding: 5px 10px;
}
.app-header .navbar .nav-link:hover{
	border-bottom:2px solid rgba(220, 53, 69, 0.5);
}
.app-header .navbar .nav-item a{
	font-size: 20px;
}  
.shopat .lazada {
	background-color: #000083 !important;
	white-space: nowrap;
}

.shopat .shopee {
	background-color: #EE4D2D !important;
	white-space: nowrap;
}

.app-header a.nav-link.active{
	border-bottom:2px solid rgba(220, 53, 69, 0.5);
}
.bg-darkred {
	background-color: #ce1212 !important;
	font-size: 14px;
}
.app-header .bg-secondary {
	font-size: 14px;
}
.app-header  .top-hmenu{
	padding: 0;
    list-style-type: none;
    display: inline-flex;
    margin-bottom: 0;
}
#customHeaderMenu .dropend .dropdown-toggle::after {
    float: right;
    position: relative;
    top: 10px;
}
#customHeaderMenu .noArrowRight::after {
    display: none !important;
}

#customHeaderMenu li.nav-item.dropdown.dropend {
    padding: 2px 10px !important;
}
#customHeaderMenu li.nav-item.dropdown.dropend a {
    padding: 3px;
}
/*--------------------------------------------------------------
# FullSlider
--------------------------------------------------------------*/
.full-slider {
	background-color: #000;
	filter: drop-shadow(0px 6px 6px rgba(0,0,0,0.2));
}
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f00' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f00' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    width: 3rem !important;
    height: 3rem !important;
}
/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
/* .about-us .about-details p{ */
	/* border-left:2px solid #dc3545; */
    /* padding-left: 30px; */
    /* border-radius: 15px; */
/* } */
/* .about-us .about-details p:hover{ */
	/* background-color: rgb(238, 238, 238, 0.8); */
/* } */
.aboutUs-details cite .bi {
	font-size:50px;
}
.aboutUs-details cite .bi::before {
	vertical-align: 1em !important;
}

.aboutUs-details blockquote p {
	font-size:18px;
}
.aboutUs-details .blockquote-footer {
	margin-top: 3px;
}
/*--------------------------------------------------------------
# Stats Counter Section
--------------------------------------------------------------*/
.stats-counter {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./assets/img/bg-stats.jpg") center center;
  background-size: contain;
  padding: 120px 0;
}

@media (min-width: 1365px) {
  .stats-counter {
    background-attachment: fixed;
  }
}

.stats-counter .stats-item {
  padding: 30px;
  width: 100%;
}

.stats-counter .stats-item span {
  font-size: 48px;
  display: block;
  color: #fff;
  font-weight: 700;
}

.stats-counter .stats-item p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.6);
}


/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact-us .info-item {
  background: #f4f4f4;
  padding: 30px;
  height: 100%;
}

.contact-us .info-item .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  font-size: 24px;
  line-height: 0;
  color: #fff;
  background: #ce1212;
  border-radius: 50%;
  margin-right: 15px;
}

.contact-us .info-item h3 {
  font-size: 20px;
  color: #6c757d;
  font-weight: 700;
  margin: 0 0 5px 0;
}

.contact-us .info-item p {
  padding: 0;
  margin: 0;
  line-height: 24px;
  font-size: 14px;
}

.contact-us .info-item .social-links a {
  font-size: 24px;
  display: inline-block;
  color: rgba(55, 55, 63, 0.7);
  line-height: 1;
  margin: 4px 6px 0 0;
  transition: 0.3s;
}

.contact-us .info-item .social-links a:hover {
  color: #ce1212;
}

.contact-us .form input, .contact-us .form textarea, .contact-us .form button {
	border-radius: 0 !important;
}

/*--------------------------------------------------------------
# Product
--------------------------------------------------------------*/
.imgbox {
	height: 200px;
	display: flex;
    align-items: center;
}
.imgbox:hover {
	border: 1px solid #ddd;
	border-radius: 4px;
}
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
	font-size: 14px;
	background-color: #1f1f24;
	padding: 50px 0;
	color: rgba(255, 255, 255, 0.7);
}

.footer .icon {
	margin-right: 15px;
	font-size: 24px;
	line-height: 0;
}

.footer h4 {
	font-size: 16px;
	font-weight: bold;
	position: relative;
	padding-bottom: 5px;
	color: #fff;
}

.footer .footer-links {
	margin-bottom: 30px;
}
.footer .social-links a {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid rgba(255, 255, 255, 0.2);
	font-size: 16px;
	color: rgba(255, 255, 255, 0.7);
	margin-right: 10px;
	transition: 0.3s;
}

.footer .social-links a:hover {
	color: #fff;
	border-color: #fff;
}

.footer .copyright {
	text-align: center;
	padding-top: 30px;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .credits {
	padding-top: 4px;
	text-align: center;
	font-size: 13px;
}

.footer .credits a {
  color: #fff;
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
	position: fixed;
	visibility: hidden;
	opacity: 0;
	right: 15px;
	bottom: 15px;
	background: #ce1212;
	width: 44px;
	height: 44px;
	border-radius: 50px;
	transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.scroll-top:hover {
  background: #ec2727;
  color: #fff;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
  transition: all 0.6s ease-out;
  width: 100%;
  height: 100vh;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  border: 4px solid red;
  border-radius: 50%;
  animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader:after {
  animation-delay: -0.5s;
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
	[data-aos-delay] {
		transition-delay: 0 !important;
	}
	/* .about-details{ */
		/* margin-top: 30px; */
	/* } */
	.footer .copyright {
		margin-top: 20px;
	}
	
	.full-slider img.img-fluid.mx-auto.d-block {
		height: 300px;
		object-fit: cover;
	}
	
	.full-slider .carousel-control-prev, .full-slider .carousel-control-next {
		display:none;
	}
	
	#products .carousel-indicators{
		display:none;
	}
	
	.stats-counter {
		background-size: cover;
	}
	
	.app-header  .top-hmenu{
		display: block;
	}
}